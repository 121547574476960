import { useEffect, useState, useRef } from "react"
import { nanoid } from "nanoid"
//import mapStyles from "./styles.json";

let mapStyles = require("./styles.json")
//let translations = require("./translations.json")

const SeatMap = ({
  config,
  ticketGroups,
  highlightedSections,
  zoomedTicketGroup,
  handleSectionHighlight,
  handleSectionThumbLoaded,
  translationRules,
}) => {
  // reference to the DVM map viewer module
  const [error, setError] = useState(false)
  const viewer = useRef(null)
  const containerId = useRef(`viewer-${nanoid()}`)
  const moduleLoaded = useRef(false)
  // keep this as a ref for async callbacks
  const ticketGroupsRef = useRef()

  const thumbnails = useRef({})
  const mappedSections = useRef({})
  // translate xp section to 3ddv
  function translateSecName(section) {
    //const rules = translations[config.venueId]
    const rules = translationRules
    let translatedSec

    if (rules && rules.sections) {
      const match = rules.sections
        .filter((r) => {
          const regex = new RegExp(r.rule, "gi")
          const matches = regex.exec(section)
          return matches
        })
        .map((r) => {
          const regex = new RegExp(r.rule, "gi")
          const matches = regex.exec(section)
          let newSec
          if (r.replace.length > 0) {
            newSec = r.replace.reduce((tem, rep) => {
              return tem.replace("${" + rep + "}", matches.groups[rep])
            }, r.template)
          } else {
            // Full replacement of section name
            newSec = r.template
          }

          return newSec
        })
      if (match.length > 0) {
        translatedSec = match[0]
      } else {
        translatedSec = `S_${section}`
      }
    } else {
      translatedSec = `S_${section}`
    }
    if (mappedSections.current[translatedSec]) {
      mappedSections.current[translatedSec].add(section)
    } else {
      mappedSections.current[translatedSec] = new Set([section])
    }
    return translatedSec
  }

  useEffect(() => {
    const input_options = {
      container: containerId.current,
      callbacks: {
        click: handleMapClick,
        end_load: highlightSections,
      },
    }

    function getAvailableSectionIds(ticketGroups) {
      let availableViewerSectionIds = []
      if (ticketGroups.length > 0 && viewer.current?.isLoaded()) {
        // get all available sections in the viewer
        const viewerSections = viewer.current.getNodesByType("section")
        console.log(`viewer sections;`)
        console.log(viewerSections)
        // get all sections available in the tickets
        const ticketSections = new Set(
          ticketGroups.map((group) => {
            return translateSecName(group.section.name)
          })
        )
        // create a list of viewer section ids for available ticket sections
        availableViewerSectionIds = viewerSections
          .filter((section) => {
            return ticketSections.has(section.id)
          })
          .map((section) => {
            return section.id
          })
      }

      return availableViewerSectionIds
    }

    function getAvailableSeatIds() {
      const tg = ticketGroupsRef.current
      let availableViewerSeatIds = []

      if (tg.length > 0 && viewer.current?.isLoaded()) {
        const viewerSections = viewer.current.getNodesByState(
          "section",
          "available"
        )

        availableViewerSeatIds = viewerSections
          .map((section) => {
            return tg
              .filter((tg) => {
                // find the ticket groups for this section
                return (
                  tg.section === section.id.slice(2) &&
                  Number(tg.seat_from) &&
                  Number(tg.seat_thru)
                )
              })
              .map((tg) => {
                function range(size, startAt = 0) {
                  return [...Array(size).keys()].map((i) => i + startAt)
                }
                const seatRange = range(
                  Number(tg.seat_thru) - Number(tg.seat_from) + 1,
                  Number(tg.seat_from)
                )
                const seatIds = seatRange.map((seat) => {
                  return `${section.id}-${tg.row}-${seat}`
                })
                return seatIds
              })
          })
          .flat(2)
      }
      return availableViewerSeatIds
    }

    function highlightSections() {
      const tg = ticketGroupsRef.current
      if (tg.length > 0 && viewer.current?.isLoaded()) {
        const availableSectionIds = getAvailableSectionIds(tg)
        viewer.current.setAvailability("section", availableSectionIds)
        const availableSeatIds = getAvailableSeatIds()
        viewer.current.setAvailability("seat", availableSeatIds)
      }
    }

    function handleMapClick(obj) {
      var node = obj.nodes[0]
      console.log(`Node: ${node?.id}`)
      if (
        node?.type === "section" &&
        (node.state === "available" || node.state === "selected")
      ) {
        handleSectionHighlight(mappedSections.current[node.id])
      }
    }

    function startViewer() {
      const load_options = {
        venue_id: config.venueId,
        map_id: "blockmap2d",
      }

      viewer.current
        .loadMap(load_options)
        .then(function (obj) {
          viewer.current.max_selection = 10
          viewer.current.setStyles(mapStyles)
          viewer.current.max_scaling_factor = 50
          if (ticketGroups.length > 0) {
            //viewer.flags.automatic_selection = true;
            const availableSections = getAvailableSectionIds(ticketGroups)
            viewer.current.setAvailability("section", availableSections)
          }
        })
        .catch(function (err) {
          setError(true)
          // Error while loading
          console.error(`ERROR loading seat map ${err}`)
        })
    }

    // only load the viewer module once
    if (config?.venueId && !moduleLoaded.current) {
      moduleLoaded.current = true
      window.DVM.loadModule("map_viewer", input_options)
        .then(function (viewerMod) {
          viewer.current = viewerMod
          startViewer()
        })
        .catch(function (err) {
          console.error(`ERROR loading module ${err}`)
        })
    }

    ticketGroupsRef.current = ticketGroups

    highlightSections()

    return () => {
      console.log("RESETTING MODULE")
    }
  }, [config, ticketGroups])

  useEffect(() => {
    if (viewer.current) {
      if (zoomedTicketGroup) {
        const translatedSection = translateSecName(zoomedTicketGroup.section)
        // check if thumbnail is loaded
        if (!thumbnails.current[translatedSection]) {
          viewer.current
            .getThumbnail(translatedSection, undefined, true)
            .then(function (url) {
              thumbnails.current[zoomedTicketGroup.section] = url
              handleSectionThumbLoaded(thumbnails.current)
            })
            .catch((error) => {
              console.log(`Error retrieving thumbnail ${error}`)
            })
        }
        const section = viewer.current
          .getNodesByType("section")
          .filter((section) => {
            return section.id == translateSecName(zoomedTicketGroup.section)
          })

        if (section.length > 0) {
          viewer.current.hover(section[0])
          //viewer.current.goTo(section[0], 2, 1000)
        }
      } else {
        viewer.current.hover()
        //viewer.current.goTo([0, 0], viewer.current.min_scaling_factor, 1000)
      }
    }
  }, [zoomedTicketGroup])

  useEffect(() => {
    if (viewer.current) {
      // Remove all tags
      viewer.current.getNodesByTag("section", "filter").map((node) => {
        viewer.current.setNodesTag(node, null)
      })

      const translatedHighlighted = new Set(
        highlightedSections.map((sec) => {
          return translateSecName(sec)
        })
      )

      viewer.current
        .getNodes({
          type: "section",
          state: "available",
        })
        .filter((node) => {
          //return highlightedSections.includes(node.id.slice(2))
          return translatedHighlighted.has(node.id)
        })
        .map((node) => {
          const result = viewer.current.setNodesTag(node, "filter")
          viewer.current.select(node)
        })

      if (highlightedSections.length === 0) {
        viewer.current.unselectAll()
      }
    }
  }, [highlightedSections])

  if (!config?.venueId || error) {
    return (
      <img
        style={{
          width: "100%",
          objectFit: "cover",
          height: "100%",
          borderRadius: "1rem 1rem 0 0",
        }}
        src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/seatmap.png"
        alt="seatmap"
      />
    )
  }

  return (
    <>
      <div id={containerId.current}></div>
    </>
  )
}

export default SeatMap
