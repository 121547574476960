import { useSelector } from "react-redux"
import classNames from "classnames"
import { useState } from "react"
import { Button, Input, Modal } from "../"
import RedeemLoading from "../../Redeem/RedeemLoading"
import { CloseCircle } from "../../css/icons"
import { Connection, Transaction } from "@solana/web3.js"
import styles from "./RedeemModal.module.scss"
import { Base64 } from "js-base64"
import {
  setConfig,
  Operator,
  TokenAccount,
  PDA,
  CNFT,
} from "@captainxyz/solana-core"
import { PublicKey } from "@solana/web3.js"
import postToSlack from "../../postToSlack"
const { keycodeDispenser } = require("../../helpers/dispenser")

const RedeemModal = ({
  hasCoupons,
  onRedeemSucces,
  onClose,
  total,
  setShowCouponSelectModal,
}) => {
  const [keycode, setKeycode] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  const user = useSelector((state) => state.user.user)

  const dispenser = async () => {
    keycodeDispenser({
      user: user,
      setLoading: setLoading,
      keycode: keycode,
      onError: (err) => {
        setLoading(false)
        setError(err)
      },
      onSuccess: (resp) => {
        //resp.metadata should have minimum $$ in json
        if (resp.metadata) {
          let minimum = resp.metadata.minimum
          if (minimum && total < minimum) {
            const formattedMinimum =
              "$" + (resp.metadata.minimum / 100).toFixed(0)
            let msg = `This promo code does not apply to this purchase because the minimum spend is ${formattedMinimum}.`
            setError(msg)
            return
          }
        }
        onRedeemSucces()
      },
    })
  }

  const closeModal = () => {
    document.body.style.overflow = "auto"
    onClose()
  }

  console.log("error is", error)

  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      width="medium"
      containerClass={styles.modalContainer}
    >
      <div className={styles.signInModal}>
        {loading ? (
          <RedeemLoading
            useOneOffCopy={window.location.pathname.includes("decrypt")}
          />
        ) : (
          <>
            <div className={styles.modalHeader}>
              <CloseCircle
                onClick={() => {
                  document.body.style.overflow = "auto"
                  onClose()
                }}
              />
            </div>
            <h1>Apply a Promo Code</h1>

            <div className={styles.subHeader}>
              Enter your promo code below, or select a discount already in your
              account.
            </div>

            <div className={styles.label}>ADD PROMO CoDE</div>
            <Input
              className={styles.input}
              disableAutocomplete={true}
              placeholder="Enter Promo Code"
              value={keycode}
              onChange={(e) => setKeycode(e.target.value)}
            ></Input>

            {error && (
              <div
                className={classNames(styles.subHeader, styles.subHeaderError)}
              >
                {error}
              </div>
            )}

            <Button
              fullWidth
              disabled={!(keycode && keycode.length >= 6)}
              className={styles.modalButton}
              onClick={dispenser}
            >
              Use Promo Code
            </Button>

            {hasCoupons && (
              <>
                <div className={styles.dividerContainer}>
                  <div className={styles.divider} />
                  <span className={styles.or}>OR</span>{" "}
                  <div className={styles.divider} />
                </div>
                <Button
                  className={styles.rewardButton}
                  fullWidth
                  onClick={() => {
                    setShowCouponSelectModal && setShowCouponSelectModal(true)
                    onClose()
                  }}
                >
                  Select Discount from Account
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

export default RedeemModal
