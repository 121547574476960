export const Telegram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.6651 3.71682L2.93509 10.5538C1.72509 11.0398 1.73209 11.7148 2.71309 12.0158L7.26509 13.4358L17.7971 6.79082C18.2951 6.48782 18.7501 6.65082 18.3761 6.98282L9.8431 14.6838H9.84109L9.8431 14.6848L9.5291 19.3768C9.9891 19.3768 10.1921 19.1658 10.4501 18.9168L12.6611 16.7668L17.2601 20.1638C18.1081 20.6308 18.7171 20.3908 18.9281 19.3788L21.9471 5.15082C22.2561 3.91182 21.4741 3.35082 20.6651 3.71682Z"
      fill="#FDFCEA"
    />
  </svg>
)
