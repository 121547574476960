import classNames from "classnames"
import styles from "./DateTag.module.scss"

const DateTag = ({ className, event }) => (
  <div className={classNames(styles.cardDateTag, className)}>
    <div className={styles.month}>{event?.date_month}</div>
    <div className={styles.day}>{event?.date_day}</div>
  </div>
)




export default DateTag
