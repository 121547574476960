import { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { Button } from "../"
import styles from "./LocationPicker.module.scss"
import { LocationArrow, Search } from "../../css/icons"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"
import FilterButton from "../FilterButton/FilterButton"

const LocationPicker = ({
  containerClass,
  className,
  nearbyName,
  updateLocation,
}) => {
  const [results, setResults] = useState([])
  const [locationQuery, setLocationQuery] = useState([])
  const [nearby, setNearby] = useState(false)
  const [showLocationMenu, setShowLocationMenu] = useState(false)

  const locationMenuRef = useRef()
  useDetectClickOutside(locationMenuRef, () => {
    setShowLocationMenu(false)
    setResults([])
    setLocationQuery("")
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      getAutocompleteSuggestions(locationQuery)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [locationQuery])

  const getAutocompleteSuggestions = async (query) => {
    if (query === "") {
      setResults([])
      return
    }

    if (query?.length == 0) {
      setResults([])
      return
    }

    try {
      const endpoint = "/api/stagehand/cities"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      setResults(response.cities)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={classNames(styles.menuContainer, containerClass)}>
      <FilterButton
        onClick={() => {
          setShowLocationMenu(!showLocationMenu)
        }}
        buttonCopy={nearbyName}
      ></FilterButton>
      {showLocationMenu && (
        <div
          className={classNames(styles.locationMenuContainer, className)}
          ref={locationMenuRef}
        >
          <div className={styles.inputContainer}>
            <Search />
            <input
              autoFocus
              placeholder="Search by city"
              autoComplete="new-password"
              onChange={(e) => setLocationQuery(e.target.value)}
            />
          </div>
          {results.length > 0 && (
            <div className={styles.locationMenuContent}>
              <div>
                {results.map((result) => {
                  return (
                    <div
                      className={classNames(styles.locationMenuItem)}
                      onClick={() => {
                        updateLocation(result)
                        setNearby(false)
                        setShowLocationMenu(false)
                        setResults([])
                        setLocationQuery("")
                      }}
                    >
                      <span>
                        {result.city}, {result.state}
                      </span>
                      {/*location === result.cbsaname && <CheckMark />*/}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LocationPicker
