import { useState } from "react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { Button, Input, Modal } from "../components"
import { Bell, CloseCircle, Explosion, Phantom } from "../css/icons"
import styles from "./SignInModal.module.scss"
import Verification from "./Verification"
import postToSlack from "../postToSlack"
import EmailCapture from "./EmailCapture"

const Api = require("../api")

const SignInModal = ({ onClose, startGame, shouldCaptureEmail }) => {
  const [phoneSelected, setPhoneSelected] = useState(false)
  const [formattedNumber, setFormattedNumber] = useState("")

  const [number, setNumber] = useState("")
  const [authUUID, setAuthUUID] = useState("")
  const [codeSent, setCodeSent] = useState(false)
  const [showEmailCapture, setShowEmailCapture] = useState(false)

  const validateAndSendText = () => {
    let stripped = number.replace(/ /g, "")
    let message = `${stripped} is starting to login`
    postToSlack(message, "firehose")
    Api.callApi({
      endpoint: "/supperclub/get-login-code",
      method: "post",
      data: { phone: stripped, ticketdex: true },
      success: (resp) => {
        if (resp.success) {
          setAuthUUID(resp.auth_uuid)
          setCodeSent(true)
        } else {
          alert("Unknown Error")
        }
      },
      failure: (err) => {
        alert("Unknown Error")
      },
    })
  }

  const closeModal = () => {
    document.body.style.overflow = "auto"
    onClose()
  }

  function phoneFormat(input) {
    if (!input) return ""
    //returns (###) ###-####
    // Remove all non-digit characters from the input
    var cleaned = input.replace(/\D/g, "")

    // Check if the input is empty or contains non-digit characters
    if (cleaned === "" || isNaN(cleaned)) {
      return "" // Return empty string or handle invalid input
    }

    // Format the phone number (assuming 10-digit US phone number)
    var formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")

    return formatted
  }

  const handleChange = (e) => {
    let value = e.target.value?.replace(/[()-]/g, "")
    if (value && value.length < 12) {
      setNumber(e.target.value)
      setFormattedNumber(phoneFormat(e.target.value))
    } else {
      setNumber("")
      setFormattedNumber("")
    }
  }

  const disabled = number?.length !== 10

  if (showEmailCapture) {
    return (
      <EmailCapture
        startGame={startGame}
        onClose={() => {
          setShowEmailCapture(false)
          onClose()
        }}
      />
    )
  }

  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      width="medium"
      containerClass={styles.modalContainer}
    >
      {phoneSelected && codeSent && !showEmailCapture && (
        <Verification
          startGame={startGame}
          setShowEmailCapture={setShowEmailCapture}
          onClose={closeModal}
          phoneNumber={formattedNumber}
          setCodeSent={setCodeSent}
          authUUID={authUUID}
          validateAndSendText={validateAndSendText}
          setPhoneSelected={setPhoneSelected}
          shouldCaptureEmail={shouldCaptureEmail}
        />
      )}
      {!phoneSelected && !codeSent && (
        <div className={styles.signInModal}>
          <div className={styles.modalHeader}>
            <CloseCircle
              onClick={() => {
                document.body.style.overflow = "auto"
                onClose()
              }}
            />
          </div>
          <h1>Create Account or Sign In</h1>
          <label className={styles.label}>Enter your phone number</label>
          <Input
            className={styles.input}
            placeholder="(555) 555-5555"
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                validateAndSendText()
                setPhoneSelected(true)
              }
            }}
            value={formattedNumber}
            type="tel" // disables autofill until we can find a better solution
          ></Input>
          <Button
            fullWidth
            className={styles.modalButton}
            disabled={disabled}
            onClick={() => {
              validateAndSendText()
              setPhoneSelected(true)
            }}
          >
            Get My Sign In Code
          </Button>
          <div className={styles.dividerContainer}>
            <div className={styles.divider} />
            <p className={styles.or}>or</p>
            <div className={styles.divider} />
          </div>
          <WalletMultiButton>
            Connect a Solana Wallet
            <div>
              <Phantom />
              <Explosion />
              <Bell />
            </div>
          </WalletMultiButton>
        </div>
      )}
    </Modal>
  )
}

export default SignInModal
