// uses styles from newvelvet
import { useNavigate } from "react-router-dom"
import styles from "./NewVelvet.module.scss"
import { Button, Search } from "../components"
import { Search as SearchIcon } from "../css/icons"
import classNames from "classnames"
import VerticalCarousel from "./VerticalCarousel"

const DefaultCampaignCard = ({ campaign, setShowMobileSearch }) => {
  const navigate = useNavigate()

  window.addEventListener("load", function () {
    var video = document.getElementById("bg-video")
    const fallbackImage = document.getElementById("bg-video-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      fallbackImage.style.display = "block"
    })
  })

  return (
    <div className={classNames(styles.heroSection, styles.defaultHeroSection)}>
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        className={classNames(styles.heroVideo, styles.heroBg)}
        width={window.innerWidth}
        id="bg-video"
      >
        <source
          src={
            "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Spotlights_E.mp4"
          }
          type="video/mp4"
        />
      </video>
      <picture>
        <source
          srcset="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Spotlights_E.avif"
          type="image/avif"
        />
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Spotlights_E.avif"
          className={classNames(styles.heroFallbackImage, styles.fallbackImage)}
          alt="background"
          id="bg-video-fallback"
        />
      </picture>
      <div className={styles.defaultHeroContent}>
        <div className={styles.defaultHeroLeft}>
          {window.innerWidth > 960 ? (
            <h1 className={styles.defaultHeroHeader}>
              Tickets the way they{" "}
              <span className={styles.headerAnimation}>should</span> be.
            </h1>
          ) : (
            <>
              <h1 className={styles.defaultHeroHeader}>Tickets</h1>
              <h1 className={styles.defaultHeroHeader}>the way they</h1>
              <h1 className={styles.defaultHeroHeader}>
                <span className={styles.headerAnimation}>should</span> be.
              </h1>
            </>
          )}
          {campaign?.json.campaign.campaign_description && (
            <p className={styles.heroParagraph}>
              Upfront pricing. No hidden fees. All the best events.
            </p>
          )}

          {campaign?.json.campaign.show_search ? (
            <>
              {window.innerWidth > 960 ? (
                <Search withButton />
              ) : (
                <div className={styles.searchBorder1}>
                  <div className={styles.searchBorder2}>
                    <div
                      className={classNames(styles.searchContainer)}
                      onClick={() => setShowMobileSearch(true)}
                    >
                      <SearchIcon />
                      Search for events
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Button
              className={styles.heroButton}
              variant="beigeOutline"
              onClick={() => {
                const link = campaign?.json.campaign.campaign_cta_link
                if (link !== "") {
                  if (link.includes("http")) {
                    window.open(link, "_blank")
                  } else {
                    navigate(link)
                  }
                }
              }}
            >
              {campaign?.json.campaign.campaign_cta_text}
            </Button>
          )}
        </div>
        {window.innerWidth > 1350 &&
          campaign?.json.hero_card_carousel?.length > 0 && (
            <VerticalCarousel campaign={campaign} />
          )}
      </div>
      <div className={styles.heroDisclaimerContainer}>
        <div className={styles.heroDisclaimer}>
          Any trademarks seen on this site belong to their rightful owners, who
          may not be affiliated with or endorse XP.
        </div>
      </div>
    </div>
  )
}

export default DefaultCampaignCard
