import React, { useEffect, useState, useRef } from "react"
import styles from "./Promo.module.scss"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { getCoupon } from "../../helpers/getTokenAccounts"
import postToSlack from "../../postToSlack"
import Button from "../Button/Button"
import { Modal, RedeemModal } from "../"
import { CloseCircle, Gift, Warning, X } from "../../css/icons"
import SignInModal from "../../Authentication/SignInModal"

const { sleep } = require("../../helpers/sleep")
const { formatCents } = require("../../helpers/money")

const Promo = (props) => {
  const [coupon, setCoupon] = useState(false)
  const [showRedeemModal, setShowRedeemModal] = useState(false)
  const [applyDiscountClicked, setApplyDiscountClicked] = useState(false)
  const [minimumNotMet, setMinimumNotMet] = useState(false)
  const [minimum, setMinimum] = useState(null)
  const [discountApplied, setDiscountApplied] = useState(false)
  const user = useSelector((state) => state.user.user)
  const [showCouponSelectModal, setShowCouponSelectModal] = useState(false)
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [multipleCoupons, setMultipleCoupons] = useState([])
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [unusableCoupons, setUnusableCoupons] = useState([])
  const [checkedForCoupon, setCheckedForCoupon] = useState(
    window.checkedForCoupon
  )
  const showRedeemModalRef = useRef(false)

  useEffect(() => {
    return () => {
      setCheckedForCoupon(false)
      window.checkedForCoupon = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user?.publicKey) {
      console.log("check no pub key")
      return
    }
    if (!window.checkedForCoupon) checkForCoupon()
    else console.log("check already checked")
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForCoupon = async () => {
    console.log("check for coupon", props)
    if (window.checkedForCoupon) {
      console.log("check already")
      return
    }
    if (!props.total) {
      console.log("check props total not set")
      setTimeout(() => checkForCoupon(), 100)
      return
    }
    let { coupons, unavailableCoupons } = await getCoupon(user, props.total)
    window.checkedForCoupon = true
    setCheckedForCoupon(true)

    setMultipleCoupons(coupons)
    setUnusableCoupons(unavailableCoupons)
  }

  const checkForCouponAfterRedeem = async () => {
    let { coupons, unavailableCoupons } = await getCoupon(user, props.total)
    console.log("checking for  coupon after redeem")
    if ([...coupons, ...unavailableCoupons].length === 0) {
      console.log("no coupon after redeem")
      await sleep(1000)
      return await checkForCouponAfterRedeem()
    } else {
      console.log("yes coupon after redeem")
      // do we actually need to return something here
      return {
        coupon: coupons,
        // minimumIsMet: minimumIsMet,
      }
    }
  }

  const applyDiscount = async (c) => {
    setApplyDiscountClicked(true)
    let coupon_ = c || coupon
    let success = await props.setCoupon(coupon_)
    if (success) {
      setDiscountApplied(true)
      let msg = `applied promo ${coupon_.mint.address.toString()} during checkout`
      postToSlack(msg, "firehose", user)
    } else {
      let msg = `had error applying promo ${coupon_.mint.address.toString()} during checkout`
      postToSlack(msg, "firehose", user)
    }
  }

  // on success, apply the coupon and close the modal
  const onRedeemSucces = async () => {
    await checkForCouponAfterRedeem()
    //window.checkedForCoupon = false
    //setCheckedForCoupon(false)
    let result = await getCoupon(user, props.total)
    let availableCoupons = [...result.coupons, ...result.unavailableCoupons]

    if (availableCoupons.length > 1) {
      setMultipleCoupons(result.coupons)
      setUnusableCoupons(result.unavailableCoupons)
      setShowCouponSelectModal(true)
      setShowRedeemModal(false)
      showRedeemModalRef.current = false

      return
    } else if (result.coupons.length === 1) {
      let c = result.coupons[0]
      setMultipleCoupons(result.coupons)
      applyDiscount(c)
      setApplyDiscountClicked(true)
      setShowRedeemModal(false)
      showRedeemModalRef.current = false
    } else {
      setApplyDiscountClicked(true)
      setMinimumNotMet(true)
      setMinimum(
        formatCents(result.unavailableCoupons[0]?.metadata?._json.minimum)
      )
    }
  }

  const removeDiscount = async () => {
    setApplyDiscountClicked(false)
    setDiscountApplied(false)
    props.removeCoupon()
    setSelectedCoupon(null)
    setShowCouponSelectModal(false)
  }

  //if (!coupon) return null

  if (discountApplied) {
    return (
      <div
        {...props}
        className={classNames(styles.container, styles.containerApplied)}
      >
        <div className={styles.containerLeft}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classNames(styles.leftTopText, styles.appliedText)}>
              Rewards Applied ✅
            </div>
          </div>
        </div>

        <Button
          variant="beigeOutline"
          compact
          className={styles.removeButton}
          onClick={() => {
            removeDiscount()
          }}
        >
          <div>Remove</div>
        </Button>
      </div>
    )
  }

  if (minimumNotMet && applyDiscountClicked) {
    return (
      <div className={styles.minimumNotMet}>
        <div className={styles.minimumNotMetLeft}>
          <div className={styles.minimumNotMetTop}>
            Minimum Spend Required ⚠️
          </div>
          <div className={styles.minimumNotMetBottom}>
            Hit a <b>{minimum}</b> minimum to apply reward
          </div>
        </div>
        <X
          style={{ cursor: "pointer" }}
          onClick={() => {
            setApplyDiscountClicked(false)
          }}
        />
      </div>
    )
  }

  return (
    <>
      {showSigninModal && (
        <SignInModal onClose={() => setShowSigninModal(false)} />
      )}
      {showRedeemModal && (
        <RedeemModal
          total={props.total}
          onRedeemSucces={onRedeemSucces}
          onClose={() => {
            setShowRedeemModal(false)
            showRedeemModalRef.current = false
          }}
          setShowCouponSelectModal={() => setShowCouponSelectModal(true)}
          hasCoupons={
            [...multipleCoupons, ...unusableCoupons].length > 0 || coupon
          }
        />
      )}
      {showCouponSelectModal && (
        <Modal
          onClose={() => setShowCouponSelectModal(false)}
          width="medium"
          containerClass={styles.rewardModalContainer}
          className={styles.rewardModal}
        >
          <div className={styles.selectRewardModal}>
            <CloseCircle
              className={styles.close}
              onClick={() => setShowCouponSelectModal(false)}
            />

            <h1 className={styles.modalTitle}>Apply a Reward</h1>
            <div className={styles.modalText}>
              Choose a reward to apply to your purchase.
            </div>
            <div
              className={styles.promoLink}
              onClick={() => {
                setShowRedeemModal(true)
                showRedeemModalRef.current = true
                setShowCouponSelectModal(false)
              }}
            >
              Add a promo code
            </div>
            <div className={styles.rewardContainer}>
              <p className={styles.rewardTitle}>
                {multipleCoupons.length} Promo Code
                {multipleCoupons.length === 1 ? "" : "s"} Detected
              </p>
              {multipleCoupons?.map((c) => (
                <div
                  className={classNames(
                    styles.rewardCard,
                    selectedCoupon === c && styles.selected
                  )}
                  key={c.mint.address.toString()}
                  onClick={() => setSelectedCoupon(c)}
                >
                  <img src={c.metadata._json.image} alt="reward" />
                  <div className={styles.rewardCardInfo}>
                    <div className={styles.rewardCardTitle}>
                      {c.metadata._json.name}
                    </div>
                    {c.metadata._json.discount_type === "percent" && (
                      <div className={styles.rewardCardDiscount}>
                        {c.metadata._json.discount * 100}% off order
                      </div>
                    )}
                    {c.metadata._json.discount_type === "flat" && (
                      <div className={styles.rewardCardDiscount}>
                        {formatCents(c.metadata._json.discount)} off order
                      </div>
                    )}
                    {!!c.metadata._json.minimum &&
                      c.metadata._json.minimum > 0 && (
                        <div className={styles.rewardMinimum}>
                          {formatCents(c.metadata._json.minimum)} minimum spend
                        </div>
                      )}
                  </div>
                </div>
              ))}
              {unusableCoupons.length > 0 && (
                <p
                  className={classNames(
                    styles.rewardTitle,
                    styles.unavailableTitle
                  )}
                >
                  Unavailable Rewards
                </p>
              )}
              {unusableCoupons?.map((c) => (
                <div
                  className={classNames(styles.rewardCard, styles.unusableCard)}
                  key={c.mint.address.toString()}
                >
                  <img src={c.metadata._json.image} alt="reward" />
                  <div className={styles.rewardCardInfo}>
                    <div className={styles.rewardCardTitle}>
                      {c.metadata._json.name}
                    </div>
                    {c.metadata._json.discount_type === "percent" && (
                      <div className={styles.rewardCardDiscount}>
                        {c.metadata._json.discount * 100}% off order
                      </div>
                    )}
                    {c.metadata._json.discount_type === "flat" && (
                      <div className={styles.rewardCardDiscount}>
                        {formatCents(c.metadata._json.discount)} off order
                      </div>
                    )}

                    {!!c.metadata._json.minimum && (
                      <div className={styles.rewardMinimum}>
                        {formatCents(c.metadata._json.minimum)} minimum spend
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {multipleCoupons.length === 0 && unusableCoupons.length > 0 && (
              <div className={styles.unusableWarning}>
                You have available rewards that do not meet the minimum spend
                <Warning />
              </div>
            )}
            <Button
              className={styles.rewardButton}
              disabled={multipleCoupons.length === 0 || !selectedCoupon}
              onClick={() => {
                applyDiscount(selectedCoupon)
              }}
              fullWidth
              rightIcon={<Gift />}
            >
              Apply Reward
            </Button>
          </div>
        </Modal>
      )}

      <div {...props} className={styles.container}>
        <div className={styles.containerLeft}>
          <div className={styles.leftText}>
            {checkedForCoupon ? (
              <>
                {(coupon || multipleCoupons.length > 0) && (
                  <div className={styles.leftTopText}>Reward Detected 🎁</div>
                )}
              </>
            ) : (
              <div className={styles.leftTopText}>
                {window.innerWidth > 480
                  ? "Scanning for rewards..."
                  : "Scanning wallet..."}
              </div>
            )}
          </div>
        </div>

        <Button
          variant="outline"
          className={styles.applyDiscountButton}
          onClick={() => {
            if (!user?.publicKey) {
              let msg =
                "Not logged in when pressing promo on checkout, showing modal"
              postToSlack(msg, "firehose", user)
              setShowSigninModal(true)
            } else if (multipleCoupons.length > 0) {
              setShowCouponSelectModal(true)
            } else {
              setShowRedeemModal(true)
              showRedeemModalRef.current = true
            }
          }}
        >
          <div>
            {window.innerWidth > 480 ? "Apply Promo Code" : "Apply Promo"}
          </div>
        </Button>
      </div>
    </>
  )
}

export default Promo
