import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import styles from "./VerticalCarousel.module.scss"
import classNames from "classnames"
import { AngleRight } from "../css/icons"

const EventCard = ({ card, eventId, index }) => {
  const navigate = useNavigate()
  const [event, setEvent] = useState(null)
  useEffect(() => {
    getEvent(eventId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getEvent = async (eventId) => {
    try {
      let headers = {
        "Content-Type": "application/json",
      }
      const endpoint = "/api/stagehand/event"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: eventId }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      setEvent(response)
    } catch (err) {
      return null
    }
  }

  if (!event) return null

  return (
    <div
      className={classNames(styles.card, styles[`card${index}`])}
      onClick={() => navigate(`/event/${eventId}`)}
    >
      <h2>{card.card_title}</h2>
      <picture className={styles.cardImg}>
        <source srcset={event.image_avif} type="image/avif" />
        <source srcset={event.image_webp} type="image/webp" />
        <img src={event.image} alt={event.title} className={styles.cardImage} />
      </picture>
      <div className={styles.eventContent}>
        <div className={styles.eventInfo}>
          <div className={styles.title}>{event.title}</div>
          <div className={styles.description}>
            {event.venue_name} {event.venue_city}, {event.venue_state}
          </div>
        </div>
        <div className={styles.eventPricing}>
          <div className={styles.pricingHeader}>All In</div>
          <div className={styles.price}>${event.min_ticket_price / 100}</div>
        </div>
      </div>
    </div>
  )
}

const ArtistCard = ({ card, artistId, index }) => {
  const navigate = useNavigate()
  const [performer, setPerformer] = useState(null)

  useEffect(() => {
    getPerformer(artistId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getPerformer = async (eventId) => {
    try {
      let headers = {
        "Content-Type": "application/json",
      }
      const endpoint = "/api/stagehand/performer-by-id"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ performer_id: artistId }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      setPerformer(response.performer)
    } catch (err) {
      return null
    }
  }

  if (!performer) return null

  return (
    <div
      className={classNames(styles.card, styles[`card${index}`])}
      onClick={() => navigate(`/artist/${artistId}`)}
    >
      <h2>{card.card_title}</h2>
      <picture className={styles.cardImage}>
        <source srcset={performer.default_img_avif} type="image/avif" />
        <source srcset={performer.default_img_webp} type="image/webp" />
        <img
          src={performer.default_img}
          alt={performer.name}
          className={styles.cardImage}
        />
      </picture>
      <div className={classNames(styles.eventContent, styles.artistContent)}>
        <div className={styles.eventInfo}>
          <div className={styles.title}>{performer.name}</div>
        </div>
        <div className={styles.eventPricing}>
          <div className={styles.artistSeeAll}>
            See all <AngleRight />
          </div>
        </div>
      </div>
    </div>
  )
}

const LinkCard = ({ card, index }) => {
  const navigate = useNavigate()
  return (
    <div
      className={classNames(styles.card, styles[`card${index}`])}
      onClick={() => navigate(card.card_link)}
    >
      <h2>{card.card_title}</h2>
      <img
        src={card.card_image}
        alt={card.card_title}
        className={styles.cardImage}
      />
      <div className={styles.eventContent}>
        <div className={styles.eventInfo}>
          <div className={styles.title}>{card.card_title}</div>
          <div className={styles.description}>{card.card_description}</div>
        </div>
      </div>
    </div>
  )
}

const VerticalCarousel = ({ campaign }) => {
  if (campaign?.json.hero_card_carousel?.length === 0) {
    return null
  }

  return (
    <div className={styles.verticalCarouselContainer}>
      {campaign?.json.hero_card_carousel.map((card, index) => {
        if (card.card_event) {
          return (
            <EventCard card={card} eventId={card.card_event} index={index} />
          )
        } else if (card.card_artist) {
          return (
            <ArtistCard card={card} artistId={card.card_artist} index={index} />
          )
        } else {
          return <LinkCard card={card} index={index} />
        }
      })}
    </div>
  )
}

export default VerticalCarousel
