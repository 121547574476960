import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Input } from "../components"
import classNames from "classnames"
import styles from "./SignInModal.module.scss"
import { AngleLeft, CloseCircle, X } from "../css/icons"
import { setUser } from "../reducers/userSlice"
import postToSlack from "../postToSlack"

const Api = require("../api")

const Verification = ({
  onClose,
  authUUID,
  phoneNumber,
  validateAndSendText,
  setPhoneSelected,
  setCodeSent,
  setShowEmailCapture,
  shouldCaptureEmail,
  startGame,
}) => {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState("")
  const [error, setError] = useState(false) // eslint-disable-line no-unused-vars
  const [success, setSuccess] = useState(false) // eslint-disable-line no-unused-vars



  useEffect(() => {
    return () => {
      window.SUBMITTING_PHONE = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault()

    if (window.SUBMITTING_PHONE) return null
    window.SUBMITTING_PHONE = true 
    let value = otp
    console.log("otp", otp)
    console.log("value", value)
    console.log("auth uuid", authUUID)
    // copied from supperclub, assuming we'll want something like this here
    // the other piece that needs to change is we don't want to automatically redirect
    // on a successful code, we want the validation to happen on clicking continue
    Api.callApi({
      endpoint: "/supperclub/check-login-code",
      method: "post",
      data: {
        code: value,
        auth_uuid: authUUID,
        ticketdex: true,
        initial_campaign: window.INITIAL_CAMPAIGN
          ? window.INITIAL_CAMPAIGN
          : false,
      },
      success: (resp) => {
        if (resp.success) {
          let message = `${resp.phone} logged in!`
          postToSlack(message, "firehose")
          // use this set returned data to state wherever it needs to go
          let u = {
            phone: resp.phone,
            publicKey: resp.public_key,
            diner_uuid: resp.diner_uuid,
            uuid: resp.xp_user_uuid,
            loginMethod: "phone",
            first_name: resp.first_name,
            last_name: resp.last_name,
            email: resp.email,
          }

          if (localStorage.getItem("campaign_name")) {
            u.authenticated = true
          }

          localStorage.setItem("user", JSON.stringify(u))
          dispatch(setUser(u))

          window.SUBMITTING_PHONE = false 

          if (shouldCaptureEmail && !resp.email) {
            setShowEmailCapture(true)
          } else if (startGame) {
            if (startGame && resp.email) {
              startGame()
            } else if (resp.email) {
              onClose()
            } else {
              setShowEmailCapture(true)
            }
          } else {
            onClose()
          }
        } else {
          window.SUBMITTING_PHONE = false 
          let message = `${resp.phone} had wrong login code`
          postToSlack(message, "firehose")
          alert("Invalid code, please try again")
        }
      },
      failure: (error) => {
        window.SUBMITTING_PHONE = false 
        console.log("error", error)
      },
    })
  }

  const handleChange = (e) => {
    setOtp(e.target.value)
  }

  return (
    <div className={styles.signInModal}>
      <div className={styles.buttonRow}>
        <div
          className={styles.angleLeft}
          onClick={() => {
            setPhoneSelected(false)
            setCodeSent(false)
          }}
        >
          <AngleLeft />
        </div>
        <CloseCircle onClick={() => onClose()}>
          <X />
        </CloseCircle>
      </div>
      <h1 className={styles.enterCode}>Enter your code</h1>
      <p>We sent you a code at {phoneNumber}</p>
      <form
        className={styles.verificationInputContainer}
        onSubmit={handleSubmit}
      >
        <Input
          className={classNames(
            styles.verificationInput,
            error && styles.fieldError,
            success && styles.fieldSuccess
          )}
          autoComplete="one-time-code"
          placeholder="0000"
          maxLength="4"
          type="number"
          name="otp"
          value={otp}
          onChange={(e) => {
            e.preventDefault()
            handleChange(e)
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e)
            }
          }}
          tabIndex="1"
        />
      </form>

      <div className={styles.modalLink} onClick={() => validateAndSendText()}>
        Resend Code
      </div>
      <Button
        fullWidth
        className={styles.verificationButton}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </div>
  )
}

export default Verification
